import React, { FC, useRef, useState } from 'react';
import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
import NewFoundItemModal from './newFoundItemModal';
import { scaleAndMinifyImage } from '../../../../../sharedPacakge/utils/imageScaleMinify';
import { Button } from '../../../../../sharedPacakge/components/Button/Button';
import { useIsMobile } from '../../../../../utils/hooks/useIsMobile';
import NewFoundCardModal from './NewFoundCardModal';
import { ListItemIcon, Menu, MenuItem } from '@mui/material';
import styles from '../../Header/LocationSelect/locationSelectModal.module.css';
import AddCardIcon from '@mui/icons-material/AddCard';
import AddAPhotoIcon from '@mui/icons-material/AddAPhoto';
import AddPhotoAlternateIcon from '@mui/icons-material/AddPhotoAlternate';

const NewFoundItemButton: FC = () => {
  const [photoItemIsOpen, setPhotoItemIsOpen] = useState(false);
  const [newCardIsOpen, setNewCardIsOpen] = useState(false);
  const [uploadedImages, setUploadedImages] = useState<File[]>([]);
  const isMobile = useIsMobile();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const selectIsOpen = Boolean(anchorEl);
  const fileInputRef = useRef<HTMLInputElement | null>(null);
  const cameraInputRef = useRef<HTMLInputElement | null>(null);

  const onFileUpload = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const files = event.target.files;

    if (files && files.length > 0) {
      const fileArray = Array.from(files); // Convert FileList to Array
      const optimizedImages = await Promise.all(
        fileArray.map(
          (file) => scaleAndMinifyImage(file, 800, 800, 0.5) // Assumes this returns Base64 or Blob URLs
        )
      );
      setUploadedImages((prev) => [...prev, ...optimizedImages]);
      setPhotoItemIsOpen(true);

      // Clear input value to allow re-uploading the same file
      event.target.value = '';
    }
  };

  const onNewCardClick = (e: React.MouseEvent) => {
    e.preventDefault();
    setNewCardIsOpen(true);
    setAnchorEl(null);
  };

  const onNewPhotoItemClick = (e: React.MouseEvent) => {
    e.preventDefault();
    setAnchorEl(null);
    cameraInputRef.current?.click();
  };
  const onSelectFileClick = (e: React.MouseEvent) => {
    e.preventDefault();
    setAnchorEl(null);
    fileInputRef.current?.click();
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const onOpenClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };

  const onClose = () => {
    setPhotoItemIsOpen(false);
    setUploadedImages([]);
  };

  return (
    <>
      <Button
        onClick={onOpenClick}
        style={{
          height: isMobile ? 40 : 40,
          minWidth: isMobile ? 52 : '100%',
          width: 'fit-content',
          padding: 0,
          gap: 14
        }}
      >
        <AddOutlinedIcon />
        {!isMobile && 'Nyt Fund'}
      </Button>
      <Menu
        id="long-menu"
        MenuListProps={{
          'aria-labelledby': 'long-button'
        }}
        anchorEl={anchorEl}
        open={selectIsOpen}
        onClose={handleClose}
      >
        <MenuItem onClick={onNewPhotoItemClick} className={styles.menuItem}>
          <ListItemIcon>
            <AddAPhotoIcon />
          </ListItemIcon>
          Tag foto
        </MenuItem>
        <MenuItem onClick={onSelectFileClick} className={styles.menuItem}>
          <ListItemIcon>
            <AddPhotoAlternateIcon />
          </ListItemIcon>
          Vælg foto
        </MenuItem>
        <MenuItem onClick={onNewCardClick} className={styles.menuItem}>
          <ListItemIcon>
            <AddCardIcon />
          </ListItemIcon>
          Tilføj Kort
        </MenuItem>
      </Menu>

      <input
        multiple
        type="file"
        accept="image/*,.heic"
        ref={fileInputRef}
        style={{ display: 'none' }} // Hide the input
        onChange={onFileUpload} // Use onChange here
      />

      <input
        type="file"
        accept="image/*"
        capture="environment"
        ref={cameraInputRef}
        style={{ display: 'none' }} // Hide the input
        onChange={onFileUpload} // Use onChange here as well
      />

      {photoItemIsOpen && uploadedImages.length > 0 && (
        <NewFoundItemModal onClose={onClose} uploadedImages={uploadedImages} />
      )}
      {newCardIsOpen && <NewFoundCardModal setIsOpen={setNewCardIsOpen} />}
    </>
  );
};

export default NewFoundItemButton;
