import React, { FC, useEffect, useRef, useState } from 'react';
import { Divider, TextField } from '@mui/material';
import 'dayjs/locale/da';
import styles from './newFoundItemButton.module.css';
import {
  CookieName,
  getBooleanCookie,
  setCookie
} from '../../../../../utils/cookies/cookie';
import { useLocationData } from '../../../../../contexts/LocationDataContext/LocationDataContext';
import { ItemCategory } from '../../../../../types/supabase/collections';
import { useSnackbar } from '../../../../../sharedPacakge/components/Snackbar/SnackbarContext';
import { scaleAndMinifyImage } from '../../../../../sharedPacakge/utils/imageScaleMinify';
import { FormModal } from '../../../../../sharedPacakge/components/Modal/FormModal/FormModal';
import { Switch } from '../../../../../sharedPacakge/components/Switch/Switch';
import CategoryDropdown from '../../../../../sharedPacakge/components/CategoryDropdown.tsx/CategoryDropdown';
import {
  OptionalNewFoundItemData,
  OptionalNewFoundItemFields
} from './components/OptionalNewFoundItemFields/OptionalNewFoundItemFields';
import { Text } from '../../../../../components/Text/Text';
import { useAuth } from '../../../../../contexts/AuthContext/AuthContext';
import { ItemStatus } from '../../../../../sharedPacakge/types/ItemStatus';
import { usePostFoundItemJobs } from '../../../../../contexts/FoundItemPosterContext/PostFoundItemJobsContext';
import { CameraPreview, dataURLtoFile } from './CameraPreview';
import { Modal } from '../../../../../sharedPacakge/components/Modal/Modal';
import { Button } from '../../../../../sharedPacakge/components/Button/Button';
import Webcam from 'react-webcam';
import { Gap } from '../../../../../components/Gap/Gap';

interface Props {
  onClose: () => void;
}

const NewFoundItemModal2: FC<Props> = ({ onClose }) => {
  const { showSnackbar } = useSnackbar();
  const { uploadingItemsAmount } = usePostFoundItemJobs();
  const { postFoundItem } = usePostFoundItemJobs();
  const { user } = useAuth();
  const { organisation, location, itemCategories } = useLocationData();
  const [useImageDetection, setUseImageDetection] = useState<boolean>(true);
  const [isMultipleItems, setIsMultipleItems] = useState<boolean>(true);
  const [loadingImageDetection, setLoadingImageDetection] =
    useState<boolean>(false);
  const [imageFiles, setImageFiles] = useState<File[]>([]);
  const [parentCategory, setParentCategory] = useState<ItemCategory | null>(
    null
  );
  const [category, setCategory] = useState<ItemCategory | null>(null);
  const [description, setDescription] = useState('');
  const [disableSubmitButton, setDisableSubmitButton] =
    useState<boolean>(false);
  const [isHighValueItem, setIsHighValueItem] = useState<boolean>(false);
  const [customFieldsIsOpen, setCustomFieldsIsOpen] = useState<boolean>(false);
  useState<boolean>(false);
  const [optionalFields, setOptionalFields] =
    useState<OptionalNewFoundItemData>({
      foundAtDateTime: new Date(),
      foundAtLocation: '',
      storageLocation: '',
      notes: ''
    });
  const webcamRef = useRef<Webcam | null>(null);

  useEffect(() => {
    if (!useImageDetection) {
      if (description === '' || !category) {
        setDisableSubmitButton(true);
      } else {
        setDisableSubmitButton(false);
      }
      return;
    }

    setDisableSubmitButton(false);
  }, [useImageDetection, description, category, imageFiles]);

  useEffect(() => {
    if (description.length > 0 || category) {
      setLoadingImageDetection(true);
    } else {
      setLoadingImageDetection(false);
    }
  }, [description, category]);

  if (!organisation || !location) return null;

  const getImage = () => {
    if (webcamRef.current) {
      const imageSrc = webcamRef.current.getScreenshot();
      const file = dataURLtoFile(imageSrc, 'image.jpg');
      return file;
    }
  };

  const onSubmit = async (e: any) => {
    e.preventDefault();

    const chosenCategory = useImageDetection
      ? itemCategories.find(({ id }) => id === 100)!
      : category;
    if (!chosenCategory)
      return showSnackbar('Kategori eller under kategori mangler', 'error');
    const image = getImage();
    if (!image) return showSnackbar('Problem med at tage billede', 'error');

    const foundItem = {
      storage_location: optionalFields.storageLocation,
      notes: optionalFields.notes,
      found_at_location: optionalFields.foundAtLocation,
      found_at_date: optionalFields.foundAtDateTime.toISOString(),
      used_image_detection: useImageDetection,
      location_id: location.id,
      organisation_id: organisation.id,
      posted_by_user_id: user!.id,
      status: ItemStatus.AwaitingMatch,
      ...(useImageDetection
        ? {
            category_id: 100,
            description: 'Oprettet',
            is_high_value_item: false
          }
        : {
            category_id: chosenCategory.id,
            description,
            is_high_value_item: isHighValueItem
          })
    };

    scaleAndMinifyImage(image, 1200, 1200, 0.5).then((minifiedImage) => {
      postFoundItem(image, {
        ...foundItem,
        imageDetection: useImageDetection,
        isMultipleItems
      });
    });

    setDescription('');
    setCategory(null);
    setIsHighValueItem(false);
  };

  const onNameChange = (e: any) => {
    setDescription(e.target.value);
  };

  const parentCategories = itemCategories
    .filter((category) => category.parent_category_id === null)
    .sort((a, b) =>
      a.name_danish.toLowerCase().localeCompare(b.name_danish.toLowerCase())
    );

  return (
    <Modal
      title="Tilføj fundet genstand"
      onClose={onClose}
      classes={{ contentContainer: styles.modalContentContainer }}
      isFullScreenMobile
      rightHeaderContent={
        <div
          onClick={(e) => {
            e.preventDefault();
            onClose();
          }}
        >
          <Text type={'body-strong'}>Færdig</Text>
        </div>
      }
    >
      <div className={styles.container}>
        {uploadingItemsAmount > 0 && (
          <Text type={'caption'} className={styles.uploading__text}>
            Uploader {uploadingItemsAmount} billeder...
          </Text>
        )}
        <div style={{ minHeight: 420 }}>
          <CameraPreview webcamRef={webcamRef} />
        </div>

        {customFieldsIsOpen && (
          <div
            style={{
              position: 'relative',
              width: '100%'
            }}
          >
            <div
              style={{
                position: 'absolute',
                width: 'calc(100% - 16px)',
                gap: 8,
                display: 'flex',
                justifyContent: 'start',
                flexDirection: 'column',
                zIndex: 40,
                background: '#FFFFFF',
                padding: '8px',
                transform: 'translateY(-100%)',
                maxHeight: 420,
                overflow: 'auto'
              }}
            >
              <OptionalNewFoundItemFields
                optionalFields={optionalFields}
                setOptionalFields={setOptionalFields}
              />
              <CategoryDropdown
                label="Kategori"
                unSelectable
                value={parentCategory}
                itemCategories={parentCategories}
                onChange={(e, newValue: ItemCategory | null) => {
                  if (newValue) {
                    // mategerial UI will get warnings if we don't use exact object refference
                    const exactObjRef = parentCategories.find(
                      (p) => p.id === newValue.id
                    ) as ItemCategory;
                    setParentCategory(exactObjRef);
                    setCategory(null);
                  } else setParentCategory(null);
                }}
              />
              {parentCategory && (
                <CategoryDropdown
                  unSelectable
                  label="Under kategori"
                  value={category}
                  itemCategories={itemCategories
                    .filter(
                      (category) =>
                        category.parent_category_id === parentCategory.id
                    )
                    .sort((a, b) =>
                      a.name_danish
                        .toLowerCase()
                        .localeCompare(b.name_danish.toLowerCase())
                    )}
                  onChange={(e, newValue: ItemCategory | null) => {
                    if (newValue) {
                      // mategerial UI will get warnings if we don't use exact object refference
                      const exactObjRef = itemCategories.find(
                        (p) => p.id === newValue.id
                      ) as ItemCategory;
                      setCategory(exactObjRef);
                    } else setCategory(null);

                    if (newValue) setIsHighValueItem(newValue.is_high_value);
                  }}
                />
              )}
              <TextField
                fullWidth
                id="outlined-basic"
                label="Beskrivelse"
                variant="outlined"
                value={description}
                onChange={onNameChange}
                disabled={loadingImageDetection}
              />
              <Switch
                uniqueId={'isHighValueFoundItem-switch'}
                state={isHighValueItem}
                label="Genstand af værdi over 500kr."
                onChange={() => setIsHighValueItem((prevState) => !prevState)}
              />
              <Divider />
            </div>
          </div>
        )}

        <div className={styles.buttons__container}>
          <Button
            buttonType={'quiet'}
            onClick={(e) => {
              e.preventDefault();
              setCustomFieldsIsOpen((prevState) => !prevState);
            }}
          >
            Custom
          </Button>
          <Button onClick={onSubmit} disabled={disableSubmitButton}>
            Post
          </Button>
        </div>
        <Gap size={8} />
        <Text type={'detail'} style={{ color: 'gray' }}>
          Tag et billede af en eller flere genstande.
        </Text>
      </div>
    </Modal>
  );
};

export default NewFoundItemModal2;
