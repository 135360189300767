import React, { FC, useRef, useState } from 'react';
import { scaleAndMinifyImage } from '../../../../../sharedPacakge/utils/imageScaleMinify';
import { Button } from '../../../../../sharedPacakge/components/Button/Button';
import { useIsMobile } from '../../../../../utils/hooks/useIsMobile';
import NewFoundCardModal from './NewFoundCardModal';
import { ListItemIcon, Menu, MenuItem } from '@mui/material';
import styles from '../../Header/LocationSelect/locationSelectModal.module.css';
import AddCardIcon from '@mui/icons-material/AddCard';
import AddAPhotoIcon from '@mui/icons-material/AddAPhoto';
import AddPhotoAlternateIcon from '@mui/icons-material/AddPhotoAlternate';
import NewFoundItemModal2 from './newFoundItemModal2';
import NewFoundItemModal from '../NewFoundItemButton/newFoundItemModal';
import { usePostFoundItemJobs } from '../../../../../contexts/FoundItemPosterContext/PostFoundItemJobsContext';

const NewFoundItemButton2: FC = () => {
  const [newPhotoItemModalIsOpen, setNewPhotoItemModalIsOpen] = useState(false);
  const { setDisableLoadingCard } = usePostFoundItemJobs();
  const [photoItemIsOpen, setPhotoItemIsOpen] = useState(false);
  const [newCardIsOpen, setNewCardIsOpen] = useState(false);
  const [uploadedImages, setUploadedImages] = useState<File[]>([]);
  const isMobile = useIsMobile();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const selectIsOpen = Boolean(anchorEl);
  const fileInputRef = useRef<HTMLInputElement | null>(null);
  const cameraInputRef = useRef<HTMLInputElement | null>(null);

  const onFileUpload = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const files = event.target.files;

    if (files && files.length > 0) {
      const fileArray = Array.from(files); // Convert FileList to Array
      const optimizedImages = await Promise.all(
        fileArray.map(
          (file) => scaleAndMinifyImage(file, 800, 800, 0.5) // Assumes this returns Base64 or Blob URLs
        )
      );
      setUploadedImages((prev) => [...prev, ...optimizedImages]);
      setPhotoItemIsOpen(true);

      // Clear input value to allow re-uploading the same file
      event.target.value = '';
    }
  };

  const onNewCardClick = (e: React.MouseEvent) => {
    e.preventDefault();
    setNewCardIsOpen(true);
    setAnchorEl(null);
  };

  const onNewPhotoItemClick = (e: React.MouseEvent) => {
    e.preventDefault();
    setNewPhotoItemModalIsOpen(true);
    setDisableLoadingCard(true);
  };
  const onSelectFileClick = (e: React.MouseEvent) => {
    e.preventDefault();
    setAnchorEl(null);
    fileInputRef.current?.click();
  };

  const onClose = () => {
    setDisableLoadingCard(false);
    setPhotoItemIsOpen(false);
    setUploadedImages([]);
    setNewPhotoItemModalIsOpen(false);
  };

  return (
    <>
      <div style={{ display: 'flex', gap: 1 }}>
        <Button
          onClick={onNewPhotoItemClick}
          style={{
            height: isMobile ? 40 : 40,
            minWidth: isMobile ? 52 : '100%',
            width: 'fit-content',
            padding: 0,
            gap: 14,
            borderRadius: '8px 0 0 8px'
          }}
        >
          <AddAPhotoIcon />
        </Button>
        <Button
          onClick={onSelectFileClick}
          style={{
            height: isMobile ? 40 : 40,
            minWidth: isMobile ? 52 : '100%',
            width: 'fit-content',
            padding: 0,
            gap: 14,
            borderRadius: 0
          }}
        >
          <AddPhotoAlternateIcon />
        </Button>
        <Button
          onClick={onNewCardClick}
          style={{
            height: isMobile ? 40 : 40,
            minWidth: isMobile ? 52 : '100%',
            width: 'fit-content',
            padding: 0,
            gap: 14,
            borderRadius: '0 8px 8px 0'
          }}
        >
          <AddCardIcon />
        </Button>
      </div>
      <input
        multiple
        type="file"
        accept="image/*,.heic"
        ref={fileInputRef}
        style={{ display: 'none' }} // Hide the input
        onChange={onFileUpload} // Use onChange here
      />
      TEST
      {photoItemIsOpen && uploadedImages.length > 0 && (
        <NewFoundItemModal onClose={onClose} uploadedImages={uploadedImages} />
      )}
      {newPhotoItemModalIsOpen && <NewFoundItemModal2 onClose={onClose} />}
      {newCardIsOpen && <NewFoundCardModal setIsOpen={setNewCardIsOpen} />}
    </>
  );
};

export default NewFoundItemButton2;
