import React, {
  useRef,
  useEffect,
  useState,
  MutableRefObject,
  FC
} from 'react';
import Webcam from 'react-webcam';
import CachedOutlinedIcon from '@mui/icons-material/CachedOutlined';
import styles from './newFoundItemButton.module.css';

interface Props {
  webcamRef: MutableRefObject<Webcam | null>;
}
export const CameraPreview: FC<Props> = ({ webcamRef }) => {
  const videoRef = useRef<HTMLVideoElement | null>(null);
  const [isFrontCamera, setIsFrontCamera] = useState(false);
  const [devices, setDevices] = useState<any>(null);
  const [cameraInUse, setCameraInUse] = useState<any>(null);

  const startCamera = (facingMode: any) => {
    let stream = null;

    // Stop any existing tracks
    if (videoRef.current && videoRef.current.srcObject) {
      stream = videoRef.current.srcObject as MediaStream;
      stream.getTracks().forEach((track: any) => track.stop());
    }

    // Access the camera with the specified facing mode
    navigator.mediaDevices
      .getUserMedia({ video: { facingMode } })
      .then((mediaStream) => {
        stream = mediaStream;
        if (videoRef.current) {
          videoRef.current.srcObject = stream;
          videoRef.current.onloadedmetadata = () => {
            videoRef.current?.play();
          };
        }
      })
      .catch((error) => {
        console.error('Error accessing camera:', error);
      });
  };

  const setEnvirementCamera = () => {
    navigator.mediaDevices.enumerateDevices().then((_devices) => {
      setDevices(_devices);

      // Find all cameras with labels that include "rear", "back", or "bag" (case-insensitive)
      const filteredCameras = _devices.filter(
        (device) =>
          device.kind === 'videoinput' && /(rear|back|bag)/i.test(device.label) // Check for keywords in a case-insensitive manner
      );

      if (filteredCameras.length > 0) {
        // Select the camera with the shortest label
        const selectedCamera = filteredCameras.reduce((prev, current) => {
          return prev.label.length < current.label.length ? prev : current;
        });

        // Start the selected camera
        startCamera(selectedCamera.deviceId);
        setCameraInUse(selectedCamera);
      }
    });
  };
  useEffect(() => {
    setEnvirementCamera();
    navigator.mediaDevices.addEventListener(
      'devicechange',
      setEnvirementCamera
    );
    startCamera(isFrontCamera ? 'user' : 'environment');

    return () => {
      navigator.mediaDevices.removeEventListener(
        'devicechange',
        setEnvirementCamera
      );

      if (videoRef.current && videoRef.current.srcObject) {
        const stream = videoRef.current.srcObject as MediaStream;
        stream.getTracks().forEach((track: any) => track.stop());
      }
    };
  }, []);

  const onTurnCameraClick = (e: any) => {
    e.preventDefault();

    setIsFrontCamera((prev) => {
      const isFront = !prev;
      const isEnvirementCamera = !isFront;
      if (isEnvirementCamera) {
        setEnvirementCamera();
      }
      return isFront;
    });
  };

  return (
    <div style={{ width: '100%', height: '100%' }}>
      <div onClick={onTurnCameraClick} className={styles.flipCamera__button}>
        <CachedOutlinedIcon />
      </div>

      <Webcam
        style={{
          width: '100%',
          height: 'fit-content',
          objectFit: 'contain',
          margin: 0,
          padding: 0
        }}
        audio={false}
        ref={webcamRef}
        screenshotFormat="image/png"
        width="100%"
        autoFocus
        videoConstraints={{
          facingMode: isFrontCamera ? 'user' : 'environment'
        }}
      />
    </div>
  );
};

export const dataURLtoFile = (dataUrl: any, filename: any) => {
  const arr = dataUrl.split(',');
  const mime = arr[0].match(/:(.*?);/)[1];
  const bstr = atob(arr[1]);
  let n = bstr.length;
  const u8arr = new Uint8Array(n);
  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }
  return new File([u8arr], filename, { type: mime });
};
